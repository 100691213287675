import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { DiGithubBadge } from "react-icons/di";
import { FaLinkedin } from "react-icons/fa";
import './Header.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  const bar1Variants = {
    closed: { rotate: 0, y: 0, backgroundColor: '#fff' },
    open: { rotate: 45, y: 7, backgroundColor: '#32CD32' },
  };
  
  const bar2Variants = {
    closed: { opacity: 1, backgroundColor: '#fff' },
    open: { opacity: 0, backgroundColor: '#32CD32' },
  };
  
  const bar3Variants = {
    closed: { rotate: 0, y: 0, backgroundColor: '#fff' },
    open: { rotate: -45, y: -7, backgroundColor: '#32CD32' },
  };

  const navVariants = {
    hidden: { x: '100%' },
    visible: {
      x: '0',
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.2,
      },
    },
    exit: { x: '100%' },
  };

  const linkVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">Erwin Kuiper</Link>
      </div>
      <motion.div
        className="menu-icon"
        onClick={toggleMenu}
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
      >
        <motion.div className="bar" variants={bar1Variants}></motion.div>
        <motion.div className="bar" variants={bar2Variants}></motion.div>
        <motion.div className="bar" variants={bar3Variants}></motion.div>
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <motion.nav
          className="nav-mobile"
          variants={navVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <motion.ul className="nav-links-mobile">
            <motion.li variants={linkVariants}>
              <NavLink to="/" onClick={toggleMenu} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                Home
              </NavLink>
            </motion.li>
            <motion.li variants={linkVariants}>
              <NavLink to="/projects" onClick={toggleMenu} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                Projects
              </NavLink>
            </motion.li>
            <motion.li variants={linkVariants} className="divider">
              <hr />
            </motion.li>
            <motion.li variants={linkVariants}>
              <NavLink to="https://github.com/ErwinKuiper" onClick={toggleMenu} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                <DiGithubBadge style={{fontSize: '2.5em'}} />
              </NavLink>
            </motion.li>
            <motion.li variants={linkVariants}>
              <NavLink to="https://www.linkedin.com/in/erwin-kuiper-447818222/" onClick={toggleMenu} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                <FaLinkedin style={{fontSize: '2.5em'}} />
              </NavLink>
            </motion.li>
          </motion.ul>
        </motion.nav>        
        )}
      </AnimatePresence>
      <nav className={`navDesktop ${isOpen ? 'open' : ''}`}>
        <ul className="nav-links">
          <li>
            <NavLink to="/" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/projects" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
              Projects
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;