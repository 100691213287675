import React from 'react';

function ProjectsPage() {
  return (
    <div>
      <h2>Projects Page</h2>
      <p>Welcome to the projects page!</p>
    </div>
  );
}

export default ProjectsPage;