import React from 'react';
import { Link } from 'react-router-dom';
import { DiGithubBadge } from "react-icons/di";
import { FaLinkedin } from "react-icons/fa";
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-column">
        <h3>Erwin Kuiper @2024</h3>
      </div>
      <div className="footer-column">
        <h3>Site Map</h3>
        <ul>
          <Link className='footerNav' to="/">Home</Link>
          <Link className='footerNav' to="/projects">Projects</Link>
        </ul>
      </div>
      <div className="footer-column">
        <h3>Social</h3>
        <div className="social-icons">
          <a href="https://github.com/ErwinKuiper" target="_blank" rel="noopener noreferrer">
            <DiGithubBadge id='github' size={30} />
          </a>
          <a href="https://www.linkedin.com/in/erwin-kuiper-447818222/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin id='linkedin' size={30} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
