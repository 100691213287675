import React from 'react';
import ErwinBackground from '../../images/Erwin_PF.jpg';
import './HomePage.css';

function HomePage() {
  return (
    <div className='introduction'>
      <div className='section'>
        <div className='text'>
          <div className='aboutMe'>
          <h2>Hi, mijn naam is Erwin Kuiper</h2>
          <p>
            Ik ben een <strong>software developer</strong> met <strong>vier jaar ervaring</strong> die ik heb opgedaan tijdens mijn opleiding aan <em>MBO-Rijnland</em>. In het eerste jaar leerde ik de basis van <code>HTML</code>, <code>CSS</code>, <code>JavaScript</code> en <code>PHP</code>, en aan het eind van het eerste jaar heb ik gekozen voor de richting backend development. Sindsdien werk ik vooral met <code>PHP</code> en <code>Laravel</code>.
          </p>
          <p>
            Volgend jaar ga ik verder studeren aan het <em>HBO</em> om mijn skills nog meer te verbeteren. Op dit moment ben ik bezig met het bouwen van een portfolio waar ik mijn projecten van mijn toekomstige HBO-studies ga laten zien. Daarnaast ben ik ook van plan om wat eigen projecten op te starten.
          </p>
          </div>
          <hr />
          <div className='languagesAndFrameworks'>
            <div className='frontend'>
              <p>Frontend</p>
              <ul>
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>React</li>
              </ul>
            </div>
            <div className='backend'>
              <p>Backend</p>
              <ul>
                <li>PHP</li>
                <li>Laravel</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='photoErwin'>
          <img src={ErwinBackground} alt='Photo of Erwin' />
        </div>
      </div>
    </div>
  );
}

export default HomePage;